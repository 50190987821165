<template>
  <div class="user-layout fill-height">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'UserLayout',

  data () {
    return {}
  },
}
</script>
